import React from "react"

import { Link } from "gatsby"
import { kebabCase } from "lodash"

import { HTMLContent } from "./Content"
import { Date } from "./Date"
import { Youtube } from "./Youtube"

const Post = ({
  content,
  contentComponent,
  description,
  authors,
  date,
  file,
  tags,
  title,
  quote,
  youtubeURL,
}) => {
  const Content = contentComponent || HTMLContent
  return (
    <>
      <h1 className="title is-size-2 has-text-weight-bold is-bold-light">{title}</h1>

      <div className="is-flex">
        <em className="mr-2">
          {authors.map((author) => (
            <span key={author}>
              {author}
              <br />
            </span>
          ))}
        </em>
        <div className="is-flex-grow-1" />
        <span>
          <strong>{description}</strong>
          <br />
          <Date>{date}</Date>
        </span>
      </div>

      {youtubeURL && <Youtube src={youtubeURL} />}

      <div>
        <Content className={["mt-4 content", quote && "quote"].join(" ")} content={content} />
      </div>

      {file && (
        <p className="has-text-centered">
          <a href={file} className="button" target="_blank">
            Leia na íntegra
          </a>
        </p>
      )}

      {tags && tags.length && (
        <div className="mt-4 is-flex is-justify-content-center">
          <ul className="post taglist">
            {tags.map((tag) => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export { Post }
