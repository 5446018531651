import React from "react"

import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout, { Section } from "../components/Layout"
import { Post } from "../components/Post"

const PostPage = ({ data }) => {
  const { markdownRemark: post } = data

  const helmet = (
    <Helmet titleTemplate="%s | Post">
      <title>{`${post.frontmatter.title}`}</title>
      <meta name="description" content={`${post.frontmatter.description}`} />
    </Helmet>
  )

  return (
    <Layout>
      <Section helmet={helmet}>
        <Post
          content={post.html}
          authors={post.frontmatter.authors || []}
          date={post.frontmatter.date}
          file={post.frontmatter.file}
          description={post.frontmatter.description}
          tags={post.frontmatter.tags}
          title={post.frontmatter.title}
          quote={post.frontmatter.quote}
          youtubeURL={post.frontmatter.youtubeURL}
        />
      </Section>
    </Layout>
  )
}

export default PostPage

export const pageQuery = graphql`
  query PostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        description
        tags
        authors
        file
        quote
        youtubeURL
      }
    }
  }
`
